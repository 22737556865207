import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm } from 'antd';
import api from '../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import * as XLSX from 'xlsx';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
  FileMarkdownOutlined,
  Loading3QuartersOutlined,
  RocketOutlined,
  PushpinOutlined,
  TagOutlined,
  LeftOutlined,
} from '@ant-design/icons';

const FlightPlan = (props) => {
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [languagesCms] = useGlobal('languagesCms');
  let [langCms] = useGlobal('langCms');
  let [langCode] = useGlobal('langCode');
  let [modules] = useGlobal('modules');
  let [mycount, setMycount] = useState(0);
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  let handleTableChange = async (page, filters, sorter) => {
    let myData = await api
      .post('/rest/flightPlan/pagination', { page, filters, sorter })
      .then(({ data }) => {
        if (data.result) {
          setTotalCount(data.result.total);
          return data.result.rows.map((item, key) => {
            if (item.active) item.active = <CheckOutlined />;
            else item.active = <CloseOutlined />;
            item.key = key;
            return item;
          });
        }
      });
    setData(myData);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'type';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name,pnr';
    }
    let restData = await api
      .get(`/rest/flightPlan?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data && data.result) {
          setTotalCount(data.result.total);
          setLoading(false);
          return data.result.rows.map((item, key) => {
            if (item.active) item.active = <CheckOutlined />;
            else item.active = <CloseOutlined />;
            item.key = key;
            return item;
          });
        }
      });
    // if (restData && restData.length > 0) {
    //   await api
    //     .get(`/rest/flight?${qs.stringify(_params)}`, { _params })
    //     .then(({ data }) => {
    //       if (data && data.result) {
    //         data.result.rows.forEach((item) => {
    //           restData.forEach((restItem,index) => {
    //             console.log("restItem", restItem)
    //             console.log("item", item)
    //             // if(item.arrival.)
    //             if(restItem.type === item.arrival.type && restItem.from.city.code === item.arrival.from.city.code && item.attendee && item.arrival.count ) {
    //               console.log("arrival.count", item.arrival.count)
    //               // restData[index].count = item.arrival.count
                 
    //               // restData.splice(index, 1, {...restItem, count: item.arrival.count})

    //             }
    //             if(restItem.type === item.departure.type && restItem.to.city.code === item.departure.to.city.code && item.attendee && item.departure.count ) {
    //               console.log("departure.count", item.departure.count)
                  
    //               // restData.splice(index, 1, {...restItem, count: item.departure.count})
    //             }
    //           });
    //         });
    //       }
    //     });
    // }
    setData(restData);
  };

  console.log("restData", data)

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item_id) => {
    api.delete(`/rest/flightPlan/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };
  let deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/flightPlan/${item._id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item._id);
      setData(newData);
      get();
    });
  };

  let getReport = async () => {
    let lang = langCode ? langCode : langCms[0].code;
    let result = await api.get(`/rest/reports/flightPlan?lang=${lang}`);
    const file = new Blob(['\ufeff', result.data]);
    let _url = window.URL.createObjectURL(file);
    let a = document.createElement('a');
    a.href = _url;
    a.download = `Uçuşlar.xls`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  let columns = [
    {
      title: 'Tip',
      dataIndex: 'type',
      key: 'type',
      render(type) {
        if (type == 'DEPARTURE') {
          return 'GİDİŞ';
        }
        return 'DÖNÜŞ';
      },
      sorter: (a, b) => a.type - b.type,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Uçuş Kodu',
      dataIndex: 'pnr',
      key: 'pnr',
      sorter: (a, b) => a.pnr - b.pnr,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Kota',
      dataIndex: 'quota',
      key: 'quota',
      sorter: (a, b) => a.quota - b.quota,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Kalan Kota',
      dataIndex: 'remainingQuota',
      key: 'remainingQuota',
      sorter: (a, b) => a.remainingQuota - b.remainingQuota,
      sortDirections: ['descend', 'ascend'],
    },
    // {
    //   title: 'Katılımcı Sayısı',
    //   dataIndex: 'count',
    //   key: 'count',
    //   sorter: (a, b) => a.quota - b.quota,
    //   sortDirections: ['descend', 'ascend'],
    //   render(count) {
    //     console.log("count",count)
    //     // count undefined ise 0 döndür
    //     return count ? count : 0;  

    //   }
    // },
    {
      title: 'Kalkış',
      dataIndex: 'from',
      key: 'from',
      render(from) {
        return from.name;
      },
      sorter: (a, b) => a.from - b.from,
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'İniş',
      dataIndex: 'to',
      key: 'to',
      render(to) {
        return to.name;
      },
      sorter: (a, b) => a.from - b.from,
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Action',
      key: 'action',
      className: 'editColumn',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Link to={'/flight/plan/edit/' + record._id}>
            <Button icon={<EditOutlined />}>
              {isSmall ? '' : languagesCms.EDIT}
            </Button>
          </Link>
          <Popconfirm
            onConfirm={() => deleteRow(record._id)}
            title={languagesCms.CHECK_DELETE}
            okText={languagesCms.OK_TEXT}
            cancelText={languagesCms.CANCEL_TEXT}
          >
            <Button type="danger" icon={<DeleteOutlined />}>
              {isSmall ? '' : languagesCms.DELETE}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Uçuşlar</h1>
        </div>
        <div className="list-buttons">
          <Button
            type="primary"
            icon={<FileMarkdownOutlined />}
            size="large"
            style={{ marginRight: '5px' }}
            onClick={() => getReport()}
          >
            {isSmall ? '' : languagesCms.EXPORT}
          </Button>
          <Link to="/flight">
            <Button
              type="light"
              icon={<LeftOutlined />}
              size="large"
              style={{ marginRight: '5px' }}
            >
              {isSmall ? '' : 'Geri'}
            </Button>
          </Link>

          <Link to="/flight/plan/add">
            <Button type="light" icon={<PlusOutlined />} size="large">
              {isSmall ? '' : languagesCms.ADD_NEW}
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Search
          type="light"
          placeholder={'Uçuş Kodu | Kalkış | İniş Ara'}
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
};

export default FlightPlan;
