import React, {useEffect, useState, useGlobal} from "reactn";
import {Table, Space, Button, Input, Popconfirm} from 'antd';
import api from '../service/index'
import {Link} from 'react-router-dom';
import qs from 'qs';
import ExportJsonExcel from 'js-export-excel';
import {
    EditOutlined,
    DeleteOutlined,
    UserOutlined,
    PlusOutlined,
    MenuOutlined,
    CheckOutlined,
    CloseOutlined,
    Loading3QuartersOutlined,
    DownloadOutlined
} from "@ant-design/icons";

const AttendeeGroup = (props) => {

    const {Search} = Input;
    let [data, setData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [selectedRows, setSelectedRows] = useState([])
    let [search, setSearch] = useState(false)
    let [loading, setLoading] = useState(false)
    let [isSmall, ] = useGlobal('isSmall');
    let [languagesCms] = useGlobal("languagesCms"); 

    let [modules] = useGlobal("modules");
    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = 'name';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = {sort: shortString, ...page,};

        if (search.length > 2) {
            _params["search"] = search.toLowerCase();
            _params["searchFields"] = "name";
        }
        let restData = await api.get(`/rest/attendeeGroups?${qs.stringify(_params)}`, {_params}).then(({data}) => {
            if (data.result) {
                setLoading(false)
                setTotalCount(data.result.total);
                return data.result.rows.map((item, key) => {
                    if (item.active)
                        item.active = <CheckOutlined/>;
                    else
                        item.active = <CloseOutlined/>;
                    item.key = key;
                    return item;
                })
            }
        });
        setData(restData);
    }

    useEffect(() => {
        if (modules) {
            setLoading(true)
            get();
        }
    }, [])

    useEffect(() => {
        get();
    }, [search]);

    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };

    let deleteRow = async (item_id) => {
        api.delete(`/rest/attendeeGroups/${item_id}`, ({data}) => {
        });
        let newData = data.filter(el => el._id !== item_id);
        setData(newData);
    }
    let deleteSelectedRows = async () => {
        selectedRows.forEach(item => {
            api.delete(`/rest/attendeeGroups/${item._id}`, ({data}) => {
            });
            let newData = data.filter(el => el._id !== item._id);
            setData(newData);
            get();
        });
    }

    let onChange = async (e) => {
        setSearch(e.target.value);
        get();
    }

    let downloadExcel = async () => {
        var option = {};
        let dataTable = [];
        let query = [];

        let excelData = await api.get(`/rest/attendeeGroups?${qs.stringify({
            page: 1,
            pageSize: 10000
        })}`, {}).then(({data}) => {
            return data.result.rows;
        });

        if (excelData) {
            for (let i in excelData) {
                if (excelData) {
                    query['email'] = excelData[i].name.replace(".pdf","");

                    let _params = {query };

                    let restData = await api
                      .get(`/rest/attendees?${qs.stringify(_params)}`, { _params })
                      .then(({ data }) => {
                        if (data.result) {
                          return data.result.rows.map((item, key) => {
                            return item;
                          });
                        }
                      });
                      console.log("restData   ",restData)
                    let obj = {
                        'id': excelData[i]._id,
                        'name': excelData[i].name,
                        'identityNumber':restData[0] ? restData[0].phone : "",
                        'active': excelData[i].active ? 'Aktif' : 'Pasif',
                    }
                    dataTable.push(obj);
                }
            }
        }


        option.fileName = 'Groups'
        option.datas = [
            {
                sheetData: dataTable,
                sheetName: 'sheet',
                sheetFilter: ['id', 'name', 'active','identityNumber'],
                sheetHeader: ['id', 'name', 'active','identityNumber'],
            }
        ];

        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    }

    let columns = [
        {
      title: languagesCms ? languagesCms.NAME : '',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: languagesCms ? languagesCms.ACTIVE : '',
            dataIndex: 'active',
            key: 'active',
            sorter: (a, b) => a.active - b.active,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Action',
            key: 'action',
            className: 'editColumn',
            width: 150,
            render: (text, record) => (
                <Space size="small">
                    <Link to={"/attendeeGroups/modules/" + record._id}>
                        <Button icon={<MenuOutlined/>}>
                            {isSmall ? '' : (languagesCms ? languagesCms.MODULES : '')}
                        </Button>
                    </Link>
                    <Link to={"/attendeeGroups/detail/" + record._id}>
                        <Button icon={<UserOutlined/>}>
                            {isSmall ? '' : 'Kişiler'}
                        </Button>
                    </Link>
                    <Link to={"/attendeeGroups/edit/" + record._id}>
                        <Button icon={<EditOutlined/>}>
                            {isSmall ? '' : (languagesCms ? languagesCms.EDIT : '')}
                        </Button>
                    </Link>
                    <Popconfirm
                        onConfirm={() => deleteRow(record._id)} title={languagesCms ? languagesCms.CHECK_DELETE : ''}
                        okText={languagesCms ? languagesCms.OK_TEXT : ''} cancelText={languagesCms ? languagesCms.CANCEL_TEXT : ''}>
                        <Button type="danger" icon={<DeleteOutlined/>}>
                            {isSmall ? '' : (languagesCms ? languagesCms.DELETE : '')}
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                    <Button size="large" onClick={downloadExcel} style={{marginRight: "5px"}}
                            icon={<DownloadOutlined/>}>{isSmall ? '' : 'Export Excel'}</Button>
                    {!isSmall && (
                        <Button type="danger" icon={<DeleteOutlined/>} size="large" onClick={deleteSelectedRows}
                                style={{marginRight: "5px"}}>{languagesCms ? languagesCms.DELETE_SELECTED : ''}</Button>
                    )}
                    <Link to="/attendeeGroups/add">
                        <Button type="light" icon={<PlusOutlined/>} size="large">{isSmall ? '' : (languagesCms ? languagesCms.ADD_NEW : '')}</Button>
                    </Link>
                </div>
            </div>

            <div className="table-wrap">
                <Search placeholder="Grup Ara" onChange={onChange} onSearch={(v) => {
                    setSearch(v);
                    get()
                }}/>
                <Table dataSource={data} columns={columns}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       onChange={handleTableChange}
                       pagination={{
                           total: totalCount
                       }}
                       rowSelection={{...rowSelection}}/>
            </div>

        </div>
    );
};


export default AttendeeGroup;
