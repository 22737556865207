import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm,Switch } from 'antd';
import api from '../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import * as XLSX from 'xlsx';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
  FileMarkdownOutlined,
  Loading3QuartersOutlined,
  CheckCircleOutlined,
  FileExclamationOutlined 
} from '@ant-design/icons';

const RoomMate = (props) => {
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [languagesCms] = useGlobal('languagesCms');
  let [langCms] = useGlobal('langCms');
  let [langCode] = useGlobal('langCode');
  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'type';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name,city,district,locality,phone,type';
    }
    let restData = await api
      .get(`/rest/roommate?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.rows.map((item, key) => {
            if (item.active) item.active = <CheckOutlined />;
            else item.active = <CloseOutlined />;
            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
  };

  
  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []); 

  useEffect(() => {
    get();
  }, [search]);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item_id) => {
    api.delete(`/rest/roommate/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };
  let deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/roommate/${item._id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item._id);
      setData(newData);
      get();
    });
  };

  let getReport = async () => {
    let lang = langCode ? langCode : langCms[0].code;
    let result = await api.get(`/rest/reports/roommate?lang=${lang}`);
    const file = new Blob(['\ufeff', result.data]);
    let _url = window.URL.createObjectURL(file);
    let a = document.createElement('a');
    a.href = _url;
    a.download = `Oda_Arkadaşı.xls`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  let getReport2 = async () => {
    let lang = langCode ? langCode : langCms[0].code;
    let result = await api.get(`/rest/reports/roommateNotFriend?lang=${lang}`);
    const file = new Blob(['\ufeff', result.data]);
    let _url = window.URL.createObjectURL(file);
    let a = document.createElement('a');
    a.href = _url;
    a.download = `Oda_Arkadaşı_Olmayanlar.xls`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  let confirmRequest = async (item_id) => {
    await api.post(`/rest/roommate/confirm`, { "request_id": item_id }, ({ data }) => {});
    let newData = data.map((el) => {
      if (el._id === item_id) {
        el.active = <CheckOutlined />;
      }
      return el;
    });
    setData(newData);
    window.location.reload(false);
  }


  let columns = [
    {
      title: '1. Kişi',
      dataIndex: 'sender',
      key: 'sender',
      render(sender) {
        if(sender) {
          return  `${sender.name} ${sender.lastname}`
        } else {
         
        }
        return ''
      },
      sorter: (a, b) => a.sender - b.sender,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '2. Kişi',
      dataIndex: 'reciver',
      key: 'reciver',
      render(reciver) {
        if(reciver) {
          return  `${reciver.name} ${reciver.lastname}`
        }
        return ''
      },
      sorter: (a, b) => a.reciver - b.reciver,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '3. Kişi',
      dataIndex: 'other',
      key: 'other',
      render(other) {
        console.log("other", other);
        if(other) {
          return  `${other.name} ${other.lastname}`
        }
        return ''
      },
      sorter: (a, b) => a.other - b.other,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Onay Durumu',
      dataIndex: 'status',
      key: 'status',
      render(status) {
        if(status == "done") {
          return  `Onaylandı`
        } 
        return 'Onay Bekliyor'
      },
      sorter: (a, b) => a.status - b.status,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Oda Tipi',
      dataIndex: 'roomType',
      key: 'roomType',
      render(roomType) {
        if(roomType) {
          return  `${roomType} Kişilik`
        }
        return '3 Kişilik'
      },
      sorter: (a, b) => a.status - b.status,
      sortDirections: ['descend', 'ascend'],
    },
    
    {
      title: 'Aksiyon',
      key: 'action',
      className: 'editColumn',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Link to={'/roommate/edit/' + record._id}>
            <Button icon={<EditOutlined />}>
              {isSmall ? '' : languagesCms.EDIT}
            </Button>
          </Link>
          <Button 
          type="light"
          icon={< CheckCircleOutlined   />}
          onClick={() => confirmRequest(record._id)}
          size="large"  
          style={{ marginRight: '5px' }}
          disabled={record.status == "done" ? true : false}
          >
              {isSmall ? '' : "Onayla"}
          </Button>
          <Popconfirm
            onConfirm={() => deleteRow(record._id)}
            title={"Oda arkadaşlığını iptal etmek istiyor musunuz?"}
            okText={languagesCms.OK_TEXT}
            cancelText={languagesCms.CANCEL_TEXT}
          >
            <Button type="danger" icon={<DeleteOutlined />}>
              {isSmall ? '' : "İptal"}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
        <Button
            type="primary"
            icon={<FileMarkdownOutlined />}
            size="large"
            style={{ marginRight: '5px' }}
            onClick={() => getReport()}
          >
            {isSmall ? '' : "Oda Arkadaşı Raporu"}
          </Button>
        <Button
            type="primary"
            icon={<FileExclamationOutlined  />}
            size="large"
            style={{ marginRight: '5px' }}
            onClick={() => getReport2()}
          >
            {isSmall ? '' : "Oda Arkadaşı Olmayan Raporu"}
          </Button>
          {!isSmall && (
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size="large"
              onClick={deleteSelectedRows}
              style={{ marginRight: '5px' }}
            >
              {languagesCms.DELETE_SELECTED}
            </Button>
          )}
          <Link to={'/roommate/add'}>
            <Button 
              icon={<EditOutlined />}
              type="light"
              size="large"
              onClick={deleteSelectedRows}
              style={{ marginRight: '5px' }}
              >
              {isSmall ? '' : languagesCms.ADD_NEW}
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Search
          type="light"
          placeholder={languagesCms.SEARCH_CITY_TYPE}
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
};

export default RoomMate;
